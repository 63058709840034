import { isValidPhoneNumber } from 'react-phone-number-input';

export const passwordIsValid = (password: string) => {
  const regex = RegExp(/^(?=.*[0-9])^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,}$/);
  return !!regex.test(password);
};

export const isPhoneValid = (phone: string, phoneDialCode?: string) =>
  !phone ||
  phone === '+' ||
  phone === `+${phoneDialCode}` ||
  isValidPhoneNumber(phone);

export const isEmailValid = (email: string) => {
  const regex = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
  return !!regex.test(email);
};

import { sortBy, stages } from '@innovamat/radiance-utils';
import { useMemo } from 'react';
import { Classroom, Course, useCoursesQuery } from '../../generated/types';

type Props = {
  regionCode: string;
  organizationId: string;
  activeLevel?: 'MANAGER' | 'DEFAULT';
};

type GroupedCourses = {
  stage: string;
  courses: Course[];
};

export type SelectCourse = Course & { available: boolean };

type useCoursesValue = {
  courses: Course[];
  isLoading: boolean;
  validCoursesByCourse: (courseOrder: number | null) => Course[];
  toOptions: (_courses: Course[]) => {
    label: string;
    value: string;
  }[];
  groupCoursesByStage: (courses: Course[]) => GroupedCourses[];
  coursesForSelectCoursePage: SelectCourse[];
  getCourseById: (courseId: string) => Course | undefined;
  getCourseByCourseOrder: (courseOrder: number) => Course | undefined | null;
  getYearUpdateCourse: (classData: Classroom) => string | undefined;
};

export function useCourses({
  regionCode,
  organizationId,
  activeLevel,
}: Props): useCoursesValue {
  const { data, isLoading } = useCoursesQuery(
    {
      organizationId,
      regionCode,
      activeLevel,
    },
    {
      enabled: !!regionCode && !!organizationId,
    }
  );

  const { data: allCoursesData } = useCoursesQuery(
    {
      regionCode: regionCode,
      activeLevel,
    },
    {
      enabled: !!regionCode,
    }
  );

  const availableCourseIds = useMemo(() => {
    return new Set(data?.courses?.map((course) => course?.id));
  }, [data?.courses]);

  const coursesForSelectCoursePage = useMemo(
    () =>
      (allCoursesData
        ?.courses!.map((course) => ({
          ...course,
          available: availableCourseIds.has(course?.id),
        }))
        .sort(sortBy('order')) as SelectCourse[]) || [],
    [availableCourseIds, allCoursesData?.courses]
  );

  const courses = useMemo(
    () => (data?.courses?.concat().sort(sortBy('order')) || []) as Course[],
    [data?.courses]
  );

  const validCoursesByCourse = (courseOrder: number | null): Course[] => {
    const isSecundariaCourse = stages.isSecundaria(courseOrder);
    const filteredCourses = courses.filter((course) => {
      if (!courseOrder) return true;
      return isSecundariaCourse
        ? stages.isSecundaria(course?.order)
        : !stages.isSecundaria(course?.order);
    });
    return filteredCourses;
  };

  const toOptions = (_courses: Course[]) => {
    return (
      _courses?.map((course) => ({
        label: course?.name!,
        value: course?.id!,
      })) || []
    );
  };

  const groupCoursesByStage = (courses: Course[]): GroupedCourses[] => {
    const groupedCourses = courses.reduce(
      (result: GroupedCourses[], course) => {
        const courseOrder = course.order;
        const stage = stages.getStageFromOrder(courseOrder || 0);

        if (stage) {
          const existingStage = result.find((group) => group.stage === stage);

          if (existingStage) {
            existingStage.courses.push(course);
          } else {
            result.push({
              stage,
              courses: [course],
            } as GroupedCourses);
          }
        }

        return result;
      },
      []
    );

    return groupedCourses;
  };

  const getCourseById = (courseId: string): Course | undefined => {
    return allCoursesData?.courses?.find(
      (course) => course?.id === courseId
    ) as Course;
  };

  const getCourseByCourseOrder = (courseOrder: number): Course | undefined => {
    return allCoursesData?.courses?.find(
      (course) => course?.order === courseOrder
    ) as Course;
  };

  const getYearUpdateCourse = (classData: Classroom) => {
    return !classData.checked ||
      (classData.provider && !classData.providerChecked)
      ? getCourseByCourseOrder(classData.courseOrder! - 1)?.name!
      : undefined;
  };

  return {
    courses,
    isLoading,
    validCoursesByCourse,
    toOptions,
    groupCoursesByStage,
    coursesForSelectCoursePage,
    getCourseById,
    getCourseByCourseOrder,
    getYearUpdateCourse,
  };
}

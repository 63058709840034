export const LANGUAGES = {
  region: {
    ES: {
      locale: {
        es: {
          fallback: [],
        },
        eu: {
          fallback: ['es'],
        },
        en: {
          fallback: ['en_US', 'es'],
        },
        ca: {
          fallback: ['es'],
        },
        va: {
          fallback: ['ca', 'es'],
        },
      },
    },
    IT: {
      locale: {
        it_IT: {
          fallback: ['en', 'en_US'],
        },
        en: {
          fallback: ['en_US', 'it_IT'],
        },
      },
    },
    'CO-A': {
      locale: {
        es_CO: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_CO', 'es_419', 'es'],
        },
      },
    },
    'CO-B': {
      locale: {
        es_CO: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_CO', 'es_419', 'es'],
        },
      },
    },
    'EC-CO': {
      locale: {
        es_EC: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_EC', 'es_419', 'es'],
        },
      },
    },
    'EC-SI': {
      locale: {
        es_EC: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_EC', 'es_419', 'es'],
        },
      },
    },
    CL: {
      locale: {
        es_CL: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_CL', 'es_419', 'es'],
        },
      },
    },
    MX: {
      locale: {
        es_MX: {
          fallback: ['es_419', 'es'],
        },
        en_US: {
          fallback: ['en', 'es_MX', 'es_419', 'es'],
        },
      },
    },
    BR: {
      locale: {
        pt_BR: {
          fallback: [],
        },
        en: {
          fallback: ['en_US', 'pt_BR'],
        },
      },
    },
    US: {
      locale: {
        en_US: {
          fallback: ['en'],
        },
        es_MX: {
          fallback: ['es'],
        },
      },
    },
    'US-LIT': {
      locale: {
        en_US: {
          fallback: ['en'],
        },
        es_MX: {
          fallback: ['es'],
        },
      },
    },
    'US-EDR': {
      locale: {
        en_US: {
          fallback: ['en'],
        },
        es_MX: {
          fallback: ['es'],
        },
      },
    },
  },
};

import { Stage } from './types/stage';

export const getStageFromOrder = (courseOrder: number): Stage | null => {
  if (courseOrder >= 3 && courseOrder <= 5) return Stage.INFANTIL;
  if (courseOrder >= 6 && courseOrder <= 11) return Stage.PRIMARIA;
  if (courseOrder >= 12 && courseOrder <= 17) return Stage.SECUNDARIA;
  return null;
};

export const isSecundaria = (courseOrder: number | null | undefined) =>
  courseOrder ? getStageFromOrder(courseOrder) === Stage.SECUNDARIA : null;

export const isInfantil = (courseOrder: number | null | undefined) =>
  courseOrder ? getStageFromOrder(courseOrder) === Stage.INFANTIL : null;

export const isPrimaria = (courseOrder: number | null | undefined) =>
  courseOrder ? getStageFromOrder(courseOrder) === Stage.PRIMARIA : null;

export const appUseLimits = {
  infantil: [15, 30],
  primaria: [45, 60, 90],
};

export const trainingUseLimits = [15, 30];

export const appUseLimitsLATAM = {
  infantil: [15, 30],
  primaria: [45, 60, 90],
};

export const appLimitsByCourse = (
  courseOrder: number | null | undefined,
  isLATAM?: boolean | null | undefined
) => {
  if (isLATAM) {
    return isInfantil(courseOrder)
      ? appUseLimitsLATAM.infantil
      : appUseLimitsLATAM.primaria;
  }
  return isInfantil(courseOrder)
    ? appUseLimits.infantil
    : appUseLimits.primaria;
};

import { truncateWords } from '@innovamat/radiance-utils';
import { ColumnWithActions, ExtendedRow, Order } from './table.types';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T): number {
  if (a[orderBy] === null) return 1;
  if (b[orderBy] === null) return -1;
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function compareIgnoreCase(a: string | number, b: string | number): number {
  if (a === null) return 1;
  if (b === null) return -1;
  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b, undefined, { sensitivity: 'base' });
  }

  return a < b ? -1 : a > b ? 1 : 0;
}

export function getComparator<T>(
  order: Order,
  orderBy: any,
  columns: ColumnWithActions<T>[]
): (a: any, b: any) => number {
  return (a, b) => {
    const column = columns.find((c) => c.id === orderBy);
    if (column && column.sortIgnoreCase) {
      return order === 'desc'
        ? compareIgnoreCase(b[orderBy], a[orderBy])
        : compareIgnoreCase(a[orderBy], b[orderBy]);
    }
    return order === 'desc'
      ? descendingComparator(a, b, orderBy)
      : -descendingComparator(a, b, orderBy);
  };
}

export const applyStyle = (
  elementId: string,
  property: keyof CSSStyleDeclaration,
  tabletValue: string,
  defaultValue: string,
  isSmallScreen: boolean
): void => {
  const element = document.getElementById(elementId);
  if (element) {
    element.style[property as any] = isSmallScreen ? tabletValue : defaultValue;
  }
};

export const getTotalStickyWidthBeforeIndex = <T>(
  columns: ColumnWithActions<T>[],
  targetIndex: number
): number => {
  const left = columns.reduce((accumulatedWidth, column, currentIndex) => {
    if (
      column.sticky &&
      currentIndex < targetIndex &&
      typeof column.width === 'number'
    ) {
      return accumulatedWidth + column.width;
    }
    return accumulatedWidth;
  }, 0);
  return left;
};

export const renderValueRow = <T>(
  column: ColumnWithActions<T>,
  value: string,
  row: ExtendedRow<T>
): string => {
  if (column.id === 'lastName' || column.id === 'firstName') {
    return truncateWords(value, 10);
  }
  return column.render?.(value, row) || value;
};

export const getLastRowSpanIndex = <T>(
  rows: ExtendedRow<T>[],
  columns: ColumnWithActions<T>[]
): number => {
  let lastRowSpanIndex = -1;

  rows?.forEach((row, rowIndex) => {
    columns.forEach((column) => {
      if (
        column.rowSpan &&
        column.rowSpan(row[column.id as keyof ExtendedRow<T>]) > 0
      ) {
        lastRowSpanIndex = rowIndex;
      }
    });
  });
  return lastRowSpanIndex;
};

export const getColumnWidth = (
  minWidth: number | string,
  id: string,
  extendableId?: string
): string | number => {
  if (extendableId === id) return 'fit-content';
  return minWidth;
};

export function distanceToTop(
  element: HTMLElement,
  appContent: HTMLElement
): number | undefined {
  let distance = 0;

  if (!appContent) return;
  while (element) {
    distance += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent as HTMLElement;
  }
  return distance - appContent.scrollTop;
}

export function handleStickyHorizontalScroll(id: string): void {
  const header = document.getElementById(`sticky-header+${id}`);
  const table = document.getElementById(`${id}`);

  if (!header || !table) return;

  header.scrollLeft = table.scrollLeft;
}

export function getScrollParent(
  node: HTMLElement | null,
  initialNode: HTMLElement | null = node
): HTMLElement | null {
  if (node === null || node === document.body) {
    return null;
  }

  if (node !== initialNode && node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    const parentNode =
      node.parentNode === document.body
        ? document.documentElement
        : (node.parentNode as HTMLElement);

    return parentNode instanceof HTMLElement
      ? getScrollParent(parentNode, initialNode)
      : null;
  }
}

export function generateRowsForTableSkeleton<T extends object>(
  count: number,
  template: T
): (T & { rowNumber: number })[] {
  const result: (T & { rowNumber: number })[] = [];

  for (let i = 0; i < count; i++) {
    result.push({
      rowNumber: i + 1,
      ...template,
    });
  }

  return result;
}

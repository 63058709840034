export const defaultRegion: RootRegion = 'ES';

export const contentsLanguages: string[] = [
  'es',
  'ca',
  'eu',
  'va',
  'en',
  'es_419',
  'es_EC',
  'fr_FR',
  'es_MX',
  'it_IT',
  'en_US',
  'pt_BR',
  'sq',
  'be',
  'es_CO',
];

export const registerLanguages: Record<Region, string[]> = {
  ES: ['es', 'ca', 'en', 'va', 'eu'],
  IT: ['it_IT', 'en'],
  CL: ['es_CL', 'en_US'],
  MX: ['es_MX', 'en_US'],
  US: ['en_US', 'es_MX'],
  'EC-CO': ['es_EC', 'en_US'],
  'EC-SI': ['es_EC', 'en_US'],
  'CO-A': ['es_CO', 'en_US'],
  'CO-B': ['es_CO', 'en_US'],
  FR: ['fr_FR'],
  BR: ['pt_BR', 'en'],
  IN2: ['en', 'es'],
  SQ: ['en'],
  BE: ['en'],
  AQA: ['en', 'es'],
  'US-LIT': ['en_US', 'es_MX'],
  'US-EDR': ['es_MX', 'en_US'],
};

export type Region = typeof regions[number];

export const regions = [
  'ES',
  'IT',
  'CL',
  'MX',
  'US',
  'EC-CO',
  'EC-SI',
  'CO-A',
  'CO-B',
  'FR',
  'BR',
  'IN2',
  'SQ',
  'BE',
  'AQA',
  'US-LIT',
  'US-EDR',
] as const;

export const REGION = {
  ES: 'ES',
  IT: 'IT',
  CL: 'CL',
  MX: 'MX',
  US: 'US',
  'EC-CO': 'EC-CO',
  'EC-SI': 'EC-SI',
  'CO-A': 'CO-A',
  'CO-B': 'CO-B',
  FR: 'FR',
  BR: 'BR',
  IN2: 'IN2',
  SQ: 'SQ',
  BE: 'BE',
  AQA: 'AQA',
  'US-LIT': 'US-LIT',
  'US-EDR': 'US-EDR',
} as const;

export type RootRegion = typeof rootRegions[number];

export const rootRegions = [
  'ES',
  'IT',
  'US',
  'CL',
  'EC',
  'MX',
  'CO',
  'IN2',
] as const;

const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));

export const getRegisterLanguagesByRegion = (
  region: string = defaultRegion
): string[] => {
  const mappedRegion = rootRegionToRegion(region);
  const clonedRegisterLanguages = deepClone(registerLanguages);

  if (!clonedRegisterLanguages[mappedRegion]) {
    return clonedRegisterLanguages[defaultRegion];
  }
  return clonedRegisterLanguages[mappedRegion];
};

export const rootRegionToRegion = (region: string = defaultRegion) => {
  if (region === 'EC') return 'EC-CO';
  if (region === 'CO') return 'CO-A';
  return region;
};

export const isValidRegion = (region: string) => {
  const mappedRegion = rootRegionToRegion(region);
  return !!regions.includes(mappedRegion as Region);
};

import { Route, Switch, useLocation } from 'react-router-dom';

import { useFlag } from '@innovamat/flags';
import { TokenData, storage } from '@innovamat/radiance-utils';
import { useState } from 'react';
import { useCollapseSidebar } from '../../hooks/use-collapse-sidebar';
import { useTopBanner } from '../../hooks/use-top-banner';
import { useUser } from '../../hooks/use-user';
import ContentRouter from '../content-router';
import { FullScreenLogoAnimation } from '../full-screen-logo-animation';
import Header from '../Header';
import MainMenu from '../MainMenu';
import StudentWithoutAccess from '../StudentWithoutAccess';
import TopBanner from '../TopBanner';
import {
  ContentArea,
  HeaderWrapper,
  Main,
  MainMenuContainer,
} from './HomeLayout.styled';
import { LazyLandingParent } from './LazyLoader';

const COOKIE_DOMAIN = import.meta.env.VITE_COOKIE_DOMAIN;

const HomeLayout = () => {
  const { currentClassroom, email, isRole, region, tokenInfo, organizationId } =
    useUser();
  const classroomName = currentClassroom?.name;
  const { isCollapsed } = useCollapseSidebar();
  const hasClassroom = classroomName !== null && classroomName !== '';
  const loadingHasClassroom = classroomName === undefined;
  const [redirectCalled, setRedirectCalled] = useState(false);

  const featureFlagUser = {
    identifier: '',
    email,
    custom: {
      region: region ?? '',
      schoolId: organizationId ?? '',
    },
  };

  const { value: isNextManagerEnabled } = useFlag(
    'nextmanager',
    false,
    featureFlagUser
  );

  const { bannerMessage, showBanner, closeBanner } = useTopBanner();

  const { pathname } = useLocation();

  const hideMenu =
    (!(
      pathname.includes('practice') ||
      pathname.includes('curriculum') ||
      pathname.includes('learning')
    ) &&
      pathname !== '/') ||
    isRole.Parent;

  const topBarClassName = showBanner ? 'topBanner' : undefined;

  if (isRole.Student && !loadingHasClassroom && !hasClassroom) {
    return <StudentWithoutAccess />;
  }

  if (isNextManagerEnabled) {
    if (!redirectCalled) {
      storage.tokenInfo.remove();
      storage.tokenInfo.setOnlyInCookie(
        tokenInfo as TokenData,
        isNextManagerEnabled ? COOKIE_DOMAIN : undefined
      );
      window.location.replace(import.meta.env.VITE_MANAGER_NEXT as string);
      setRedirectCalled(true);
    }
    return <FullScreenLogoAnimation />;
  }

  return (
    <div data-testid="HomeLayout">
      <Switch>
        <Route>
          <HeaderWrapper showBanner={showBanner}>
            {showBanner && (
              <TopBanner {...bannerMessage} closeBanner={closeBanner} />
            )}
            <Header topBarClassName={topBarClassName} />
          </HeaderWrapper>
          <Main>
            {!hideMenu && (
              <MainMenuContainer
                showBanner={showBanner}
                isCollapsed={isCollapsed}
              >
                <MainMenu />
              </MainMenuContainer>
            )}
            <ContentArea>
              {isRole.Parent ? <LazyLandingParent /> : <ContentRouter />}
            </ContentArea>
          </Main>
        </Route>
      </Switch>
    </div>
  );
};

export default HomeLayout;

import styled from '@emotion/styled';
import { Modal, Select, Typography } from '@innovamat/glimmer-components';
import { UseScheduleValues } from '../../hooks/use-classroom-schedule-values';
import { useTranslation } from 'react-i18next';

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
  width: 100%;
  > div {
    flex: 1;
  }
`;

const Description = styled(Typography.Body2)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const TimeZonesSelector = styled(Select)`
  min-width: 316px;
`;

function ClassroomSchedule({
  handleUpdateSchedule,
  values,
  timeZonesOptions,
  hoursOptions,
  handleTimeZoneCancel,
  handleTimeZoneConfirmation,
  showTimeZoneConfirmation,
}: UseScheduleValues): JSX.Element {
  const { scheduleStart, scheduleEnd, timeZone } = values;
  const { t } = useTranslation();

  return (
    <Section>
      <Typography.Subtitle2>
        {t('classroom.schedule-time.title', 'Horario lectivo')}
      </Typography.Subtitle2>
      <Description>
        {t(
          'classroom.schedule-time.description',
          'Consideramos lectivo el tiempo que los alumnos pasan en el centro. Se usa para poder discriminar la práctica realizada en la escuela y realizada fuera de ella'
        )}
      </Description>
      <Content>
        <Select
          menuPortalTarget={document.body}
          labelText={t('classroom.schedule-start', 'Entrada')}
          onChange={(option) => {
            option && handleUpdateSchedule('scheduleStart', option.value);
          }}
          options={hoursOptions}
          value={scheduleStart}
        />
        <Select
          menuPortalTarget={document.body}
          labelText={t('classroom.schedule-departure', 'Salida')}
          onChange={(option) => {
            option && handleUpdateSchedule('scheduleEnd', option.value);
          }}
          options={hoursOptions}
          value={scheduleEnd}
        />
        <TimeZonesSelector
          menuPortalTarget={document.body}
          labelText={t('classroom.time-zime', 'Zona horaria')}
          onChange={(option) =>
            option && handleUpdateSchedule('timeZone', option.value)
          }
          options={timeZonesOptions}
          value={timeZone}
        />
      </Content>
      <Modal
        zIndex={40}
        modalWidth={700}
        semantic={{
          type: 'warning',
          text: t('common.modal.important').toUpperCase(),
        }}
        onClose={handleTimeZoneCancel}
        isOpen={showTimeZoneConfirmation}
        title={t('classroom.modal.timezone.title', 'Cambio de zona horaria')}
        buttons={[
          {
            children: t('common.confirm-change', 'Sí, cambiar'),
            variant: 'accent',
            onClick: handleTimeZoneConfirmation,
          },
          {
            children: t('common.cancel'),
            variant: 'tertiary',
            onClick: handleTimeZoneCancel,
          },
        ]}
      >
        <>
          {t(
            'classroom.modal.timezone.content',
            'El cambio de zona horaria afectará a todas las clases del centro. ¿Seguro que quieres cambiar de zona horaria?'
          )}
        </>
      </Modal>
    </Section>
  );
}

export { ClassroomSchedule };

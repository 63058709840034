import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FirstYearStep, WelcomeStep, SelectPositionsStep } from '../steps';
import { Button, Typography, WizardStep } from '@innovamat/glimmer-components';
import {
  FlowGroup,
  Group1,
  Group2,
  Group3,
  OnboardingStep,
  Position,
  FLOW_GROUP,
  ONBOARDING_STEP,
} from '../onboarding-form.types';
import { LoadingManagerStep } from '../steps/loading-manager-step';
import {
  Course,
  useCourses,
  useEditSettingsMutation,
  useSettingsQuery,
} from '@innovamat/glow-api-client';
import { useUser } from '../../..';
import { SelectCoursesStep } from '../steps/select-courses-step';
import { FinishStep } from '../steps/finish-step';
import { useOnboardingFormWebhook } from '../hooks/use-onboarding-form-webhook';
import { Trans, useTranslation } from 'react-i18next';

const getFlowGroups = (positions: Position[]): FlowGroup[] => {
  const flowGroups = [] as FlowGroup[];
  if (positions.some((position) => Group1.includes(position))) {
    flowGroups.push(FLOW_GROUP.Group1);
  }
  if (positions.some((position) => Group2.includes(position))) {
    flowGroups.push(FLOW_GROUP.Group2);
  }
  if (positions.some((position) => Group3.includes(position))) {
    flowGroups.push(FLOW_GROUP.Group3);
  }
  return flowGroups;
};

type OnboardingContextType = {
  steps: WizardStep[];
  currentStep: number;
  setCurrentStep: (step: number) => void;

  selectedPositions: Position[];
  setSelectedPositions: (positions: Position[]) => void;

  isFirstYear: boolean | undefined;
  setIsFirstYear: (isFirstYear: boolean) => void;

  selectedActualCourses: number[];
  setSelectedActualCourses: Dispatch<SetStateAction<number[]>>;

  selectedBeforeCourses: number[];
  setSelectedBeforeCourses: Dispatch<SetStateAction<number[]>>;

  courses: Course[];
  goToNextStep: () => void;
  goToPrevStep: () => void;
  goToStep: (step: OnboardingStep) => void;
  onClose: () => void;
};

const OnboardingContext = createContext<OnboardingContextType | undefined>(
  undefined
);

export const OnboardingProvider = ({
  onClose,
  children,
}: {
  onClose: () => void;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const { data: settingsValues } = useSettingsQuery();
  const { mutate: setSettings } = useEditSettingsMutation();
  const { mutate: webhookMutation } = useOnboardingFormWebhook();
  const [currentStep, setCurrentStep] = useState(0);
  const [historyStack, setHistoryStack] = useState<number[]>([0]);
  const [selectedPositions, setSelectedPositions] = useState<Position[]>([]);
  const [selectedBeforeCourses, setSelectedBeforeCourses] = useState<number[]>(
    []
  );
  const [selectedActualCourses, setSelectedActualCourses] = useState<number[]>(
    []
  );
  const [isFirstYear, setIsFirstYear] = useState<boolean | undefined>(
    undefined
  );

  const { user } = useUser();

  const { courses } = useCourses({
    regionCode: user?.region!,
    organizationId: user?.organizationId!,
  });

  const actualFlowGroups = useMemo(
    () => getFlowGroups(selectedPositions),
    [selectedPositions]
  );

  const goToNextStep = (): void => {
    setHistoryStack((prev) => [...prev, currentStep + 1]);
    setCurrentStep((prev) => prev + 1);
  };

  const goToPrevStep = (): void => {
    setHistoryStack((prev) => prev.slice(0, -1));
    setCurrentStep(historyStack[historyStack.length - 2]);
  };

  const goToStep = (step: OnboardingStep): void => {
    setCurrentStep(step);
    setHistoryStack((prev) => [...prev, step]);
  };

  const getNextStep = (): OnboardingStep => {
    const isGroup1 = actualFlowGroups?.includes(FLOW_GROUP.Group1);
    const isGroup3 = actualFlowGroups?.includes(FLOW_GROUP.Group3);

    if (currentStep === ONBOARDING_STEP.FirstYear) {
      if (isGroup1)
        return isFirstYear
          ? ONBOARDING_STEP.SelectCourseNow
          : ONBOARDING_STEP.SelectBeforeCourse;
      if (isGroup3) return ONBOARDING_STEP.AppDisclaimer;
    }

    if (currentStep === ONBOARDING_STEP.SelectCourseNow) {
      if (isGroup3) {
        return ONBOARDING_STEP.AppDisclaimer;
      }
      return ONBOARDING_STEP.Finish;
    }

    return ONBOARDING_STEP.Finish as OnboardingStep;
  };

  const handleSend = (): void => {
    goToNextStep();

    const webhookData = {
      username: user?.email!,
      positions: selectedPositions,
      first_year: isFirstYear!,
      current_courses: selectedActualCourses,
      previous_courses: selectedBeforeCourses,
    };

    webhookMutation(webhookData);

    const settingsValue = {
      firstName: settingsValues?.settings?.firstName,
      lastName: settingsValues?.settings?.lastName,
      phoneNumber: settingsValues?.settings?.phoneNumber,
      positions: selectedPositions,
    };

    setSettings({ body: settingsValue });
  };

  const steps = [
    {
      id: 'welcome',
      title: t('onboarding-form.welcome-step.title'),
      description: t('onboarding-form.welcome-step.description'),
      content: <WelcomeStep />,
    },
    {
      id: 'select-positions',
      title: t('onboarding-form.select-positions-step.title'),
      description: (
        <Trans
          i18nKey="onboarding-form.select-positions-step.description"
          components={{
            i: <i />,
          }}
        />
      ),
      content: <SelectPositionsStep />,
      isNextStepDisabled: selectedPositions.length === 0,
      rightFooterActions: (
        <Button
          onClick={() => goToNextStep()}
          disabled={selectedPositions.length === 0}
        >
          {t('navigation.next')}
        </Button>
      ),
    },
    {
      id: 'first-year',
      title: actualFlowGroups?.includes(FLOW_GROUP.Group1)
        ? t('onboarding-form.first-year-step.teacher-title')
        : t('onboarding-form.first-year-step.title'),
      content: <FirstYearStep />,
      isNextStepDisabled: typeof isFirstYear === 'undefined',
      rightFooterActions: (
        <Button
          onClick={() => goToStep(getNextStep())}
          disabled={typeof isFirstYear === 'undefined'}
        >
          {t('navigation.next')}
        </Button>
      ),
    },
    {
      id: 'select-before-course',
      title: t('onboarding-form.select-before-course-step.title'),
      description: t('onboarding-form.select-course-step.description'),
      content: <SelectCoursesStep isBeforeCourses />,
      isNextStepDisabled: selectedBeforeCourses.length === 0,
      rightFooterActions: (
        <Button
          onClick={() => goToNextStep()}
          disabled={selectedBeforeCourses.length === 0}
        >
          {t('navigation.next')}
        </Button>
      ),
    },
    {
      id: 'select-course-now',
      title: t('onboarding-form.select-course-now-step.title'),
      description: t('onboarding-form.select-course-step.description'),

      content: <SelectCoursesStep />,
      isNextStepDisabled: selectedActualCourses.length === 0,
      rightFooterActions: (
        <Button
          onClick={() => goToStep(getNextStep())}
          disabled={selectedActualCourses.length === 0}
        >
          {t('navigation.next')}
        </Button>
      ),
    },
    {
      id: 'app-disclaimer',
      title: t('onboarding-form.app-disclaimer-step.title'),
      content: (
        <Trans
          i18nKey="onboarding-form.app-disclaimer-step.description"
          components={{
            a: (
              <Typography.Link1
                target="_blank"
                href={t('onboarding-form.app-disclaimer-step.link')}
              />
            ),
            br: (
              <>
                <br />
                <br />
              </>
            ),
          }}
        />
      ),
      rightFooterActions: (
        <Button onClick={() => goToNextStep()}>{t('navigation.next')}</Button>
      ),
    },
    {
      id: 'finish',
      title: t('onboarding-form.finish-step.title'),
      description: t('onboarding-form.finish-step.description'),
      content: <FinishStep />,
      rightFooterActions: (
        <Button onClick={() => handleSend()}>
          {t('onboarding-form.action.go-home')}
        </Button>
      ),
    },
    {
      id: 'loading-manager',
      content: <LoadingManagerStep />,
      hideBackButton: true,
    },
  ] satisfies WizardStep[];

  const value = {
    steps,

    currentStep,
    setCurrentStep,

    selectedPositions,
    setSelectedPositions,

    isFirstYear,
    setIsFirstYear,

    selectedActualCourses,
    setSelectedActualCourses,

    selectedBeforeCourses,
    setSelectedBeforeCourses,

    courses,

    goToNextStep,
    goToPrevStep,
    goToStep,
    onClose,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingForm = (): OnboardingContextType => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      'useOnboardingForm must be used within a OnboardingProvider'
    );
  }
  return context;
};

import { Stage } from '../types/Stage';

export type MaterialList = Record<string, { key: string; image?: string }>;

const SECONDARY_MATERIALS: MaterialList = {
  '1': {
    key: 'digitalguides.se.material.snap_cubes',
    image: 'https://2s08k9vad6in.b-cdn.net//4gvijaw0uofk-cubetsencaixables.jpg',
  },
  '2': {
    key: 'digitalguides.se.material.3d_objects',
    image: 'https://2s08k9vad6in.b-cdn.net//5pug7gqs2nld-cossos3d.jpg',
  },
  '3': {
    key: 'digitalguides.se.material.blank_dice',
    image: 'https://2s08k9vad6in.b-cdn.net//5hiqhd6rri7l-dausmuts.jpg',
  },
  '4': {
    key: 'digitalguides.se.material.dice',
    image: 'https://2s08k9vad6in.b-cdn.net//5zkaw8t0u7zl-daus.jpg',
  },
  '5': {
    key: 'digitalguides.se.material.sign_cards',
    image: 'https://2s08k9vad6in.b-cdn.net//61epfzmvx3ox-targetessigne.jpg',
  },
  '6': {
    key: 'digitalguides.se.material.number_cards',
    image: 'https://2s08k9vad6in.b-cdn.net//5tf3d2vvrq41-targetes.jpg',
  },
  '7': {
    key: 'digitalguides.se.material.segments_of_different_lengths',
    image:
      'https://2s08k9vad6in.b-cdn.net//62sku4ihhtdd-segmentslongituddiverses.jpg',
  },
  '8': {
    key: 'digitalguides.se.material.snap_polygons',
    image:
      'https://2s08k9vad6in.b-cdn.net//6821wso9yhv5-poligonsencaixables.jpg',
  },
  '9': {
    key: 'digitalguides.se.material.integer_counters',
    image: 'https://2s08k9vad6in.b-cdn.net//65ifx22w4aup-fitxesenters.jpg',
  },
  '10': {
    key: 'digitalguides.se.material.fraction_circles',
    image: 'https://2s08k9vad6in.b-cdn.net//654esq48j1td-ftrk-cir.jpg',
  },
  '11': {
    key: 'digitalguides.se.material.fraction_rectangles',
    image: 'https://2s08k9vad6in.b-cdn.net//nn18e0r1ng2-ftrk-rec.jpg',
  },
};

const EARLY_YEAR_MATERIALS: MaterialList = {
  '1': {
    key: 'digitalguides.ey.material.logical_blocks',
    image:
      'https://3dt0kb75phz7.b-cdn.net/80710e0e-6de7-48a1-bb72-b2c82e73445f',
  },
  '2': {
    key: 'digitalguides.ey.material.0-5_hand_cards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/9f5c7895-3fdd-4324-b428-ca9c609db0cf',
  },
  '3': {
    key: 'digitalguides.ey.material.symbol_and_0-10_number_cards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/a720293e-ae9c-40fe-a89a-557f0c76cc72',
  },
  '4': {
    key: 'digitalguides.ey.material.0-6_quantity_cards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/9bd6b6ce-dc3e-44e5-bd81-ad1f03ec98c5',
  },
  '5': {
    key: 'digitalguides.ey.material.0-10_egg_carton_cards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/3097305d-fcfc-46c8-8530-f66cbb804635',
  },
  '6': {
    key: 'digitalguides.ey.material.tape_measure',
    image:
      'https://3dt0kb75phz7.b-cdn.net/d760be88-c4f8-4d5b-87ed-0cc97aa1dc4d',
  },
  '7': {
    key: 'digitalguides.ey.material.wooden_3d_objects',
    image:
      'https://3dt0kb75phz7.b-cdn.net/951a5133-aa4b-44ba-a246-f518003b45dd',
  },
  '8': {
    key: 'digitalguides.ey.material.snap_cubes',
    image:
      'https://3dt0kb75phz7.b-cdn.net/348d5cec-3763-47e8-9363-224deca165a0',
  },
  '9': {
    key: 'digitalguides.ey.material.different_coloured_dice',
    image:
      'https://3dt0kb75phz7.b-cdn.net/bd9c9fdf-3deb-467e-bbf0-ab05b81e724b',
  },
  '10': {
    key: 'digitalguides.ey.material.numbered_dice',
    image:
      'https://3dt0kb75phz7.b-cdn.net/a5b06be8-4753-40e1-a57d-8469907e16dd',
  },
  '11': {
    key: 'digitalguides.ey.material.quantity_dice',
    image:
      'https://3dt0kb75phz7.b-cdn.net/e47c3f28-3b56-463e-82ce-e425d9838890',
  },
  '12': {
    key: 'digitalguides.ey.material.quiet_dice',
    image:
      'https://3dt0kb75phz7.b-cdn.net/e7e04d5b-5004-4473-b70a-6a48ee80d265',
  },
  '13': {
    key: 'digitalguides.ey.material.20x20_mirror_diptychs',
    image:
      'https://3dt0kb75phz7.b-cdn.net/f8668db5-310d-49d0-903f-fcc148e112b1',
  },
  '14': {
    key: 'digitalguides.ey.material.10x10_mirror_diptychs',
    image:
      'https://3dt0kb75phz7.b-cdn.net/6b70c002-f4ab-4c8e-a133-080c92473edf',
  },
  '15': {
    key: 'digitalguides.ey.material.anti-slip_rug',
    image:
      'https://3dt0kb75phz7.b-cdn.net/4375d9cc-5f2c-421a-918d-72ab1abf10eb',
  },
  '16': {
    key: 'digitalguides.ey.material.logical_block_labels',
    image:
      'https://3dt0kb75phz7.b-cdn.net/d62ad35f-61e1-4859-ba10-8e1d892bd3a4',
  },
  '17': {
    key: 'digitalguides.ey.material.flat_counters',
    image:
      'https://3dt0kb75phz7.b-cdn.net/b98a03d1-5039-44fe-a06d-1e2438107fa5',
  },
  '18': {
    key: 'digitalguides.ey.material.geoboards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/3582e86a-7c96-449d-a806-6a2e208852c3',
  },
  '19': {
    key: 'digitalguides.ey.material.insects',
    image:
      'https://3dt0kb75phz7.b-cdn.net/fef5f482-609d-4899-a0a0-4aa221344f08',
  },
  '20': {
    key: 'digitalguides.ey.material.bears',
    image:
      'https://3dt0kb75phz7.b-cdn.net/481bcf72-3e9a-46a9-990d-3eabe71f4278',
  },
  '21': {
    key: 'digitalguides.ey.material.pattern_blocks',
    image:
      'https://3dt0kb75phz7.b-cdn.net/e7faa98d-4b61-42ed-98d6-cfa668588959',
  },
  '22': {
    key: 'digitalguides.ey.material.soft_building_blocks',
    image:
      'https://3dt0kb75phz7.b-cdn.net/153093b3-966d-4ea5-86df-59c52745e91e',
  },
  '23': {
    key: 'digitalguides.ey.material.set_square_tiles',
    image:
      'https://3dt0kb75phz7.b-cdn.net/f4a77913-4e04-4771-9bff-ea3cb898c848',
  },
  '24': {
    key: 'digitalguides.ey.material.segments_of_different_lengths',
    image:
      'https://3dt0kb75phz7.b-cdn.net/15da7254-961e-435d-82d6-19e27ead4a96',
  },
  '25': {
    key: 'digitalguides.ey.material.segments_and_nexuses',
    image:
      'https://3dt0kb75phz7.b-cdn.net/a1ce8994-94c0-4608-95d3-47304c51e586',
  },
  '26': {
    key: 'digitalguides.ey.material.cards_0-50',
    image:
      'https://3dt0kb75phz7.b-cdn.net/587a0b57-d6f7-424c-a59f-0d7b7d950bc9',
  },
  '27': {
    key: 'digitalguides.ey.material.plane_figure_cards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/6d807e01-fd36-474f-9042-908063a30edd',
  },
  '28': {
    key: 'digitalguides.ey.material.templates_geoboards_5x5_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/cb5f907a-9f68-4c17-8279-b932fe4cd8f8',
  },
  '29': {
    key: 'digitalguides.ey.material.templates_circular_geoboards_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/7d4c4d23-c5cd-401c-9f86-de98ce53da4b',
  },
  '30': {
    key: 'digitalguides.ey.material.templates_geoboards_5x5_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/b7ccd6a2-4d14-4e85-ad03-15a01b29af9d',
  },
  '31': {
    key: 'digitalguides.ey.material.templates_circular_geoboards_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/16e52ba8-fda3-4d72-9b65-71367c3ab758',
  },
  '32': {
    key: 'digitalguides.ey.material.templates_game_board_level_1_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/81218562-24bf-4400-8a35-b753e3fb4b89',
  },
  '33': {
    key: 'digitalguides.ey.material.templates_game_board_level_2_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/447f16a5-0a0c-46f8-b12e-1aaba0e42967',
  },
  '34': {
    key: 'digitalguides.ey.material.templates_game_board_level_3_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/9ad20f77-b2fc-4cde-8aa2-b6a15e255103',
  },
  '35': {
    key: 'digitalguides.ey.material.templates_game_board_level_1_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/009c3403-9541-4c75-ab44-0d36f543c60e',
  },
  '36': {
    key: 'digitalguides.ey.material.templates_game_board_level_2_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/e76554e2-ac4d-4a55-8f9a-01072d24f47f',
  },
  '37': {
    key: 'digitalguides.ey.material.templates_game_board_level_3_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/6fc07698-5469-4f70-809d-90c58da63cff',
  },
  '38': {
    key: 'digitalguides.ey.material.templates_game_board_level_1_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/f15e7d79-4ebc-4260-b6f9-fb54334bc126',
  },
  '39': {
    key: 'digitalguides.ey.material.templates_game_board_level_2_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/5d40ada7-7c4f-439b-9be2-33b0f7ed252b',
  },
  '40': {
    key: 'digitalguides.ey.material.templates_game_board_level_3_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/a10cf06f-b1c3-4373-9dad-aa812a4aa389',
  },
  '41': {
    key: 'digitalguides.ey.material.templates_relative_position_with_bears_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/b6754cd7-ce68-452d-aa10-c2eaff498ecf',
  },
  '42': {
    key: 'digitalguides.ey.material.templates_relative_spatial_position_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/37844cf7-1164-46e2-bd7a-6646dff14cf5',
  },
  '43': {
    key: 'digitalguides.ey.material.templates_series_and_patterns_with_flat_pieces_level_1_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/9ce18a28-240d-4765-b2ff-24ae7695b55e',
  },
  '44': {
    key: 'digitalguides.ey.material.templates_series_and_patterns_with_flat_pieces_level_2_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/629928b9-b0e3-4c4a-9d13-030a6fe0dc2a',
  },
  '45': {
    key: 'digitalguides.ey.material.templates_series_and_patterns_with_snap_cubes_level_1_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/85f1453f-c866-4d8b-8415-9bbeed410b14',
  },
  '46': {
    key: 'digitalguides.ey.material.templates_series_and_patterns_with_snap_cubes_level_2_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/2f8dd298-1284-43d3-88d1-3738299f56e5',
  },
  '47': {
    key: 'digitalguides.ey.material.templates_series_and_patterns_level_3_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/3cc8bc4f-651d-4ef4-a96e-acb65f51ef14',
  },
  '48': {
    key: 'digitalguides.ey.material.templates_dashes_level_1_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/87792118-b5a5-4454-a399-c2b03c203caa',
  },
  '49': {
    key: 'digitalguides.ey.material.templates_dashes_level_2_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/86ccc0b1-25be-4c25-99b2-90669ce3e4b8',
  },
  '50': {
    key: 'digitalguides.ey.material.templates_symmetries_level_1_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/b0692bd3-5cb0-4a5c-bd53-08780955690c',
  },
  '51': {
    key: 'digitalguides.ey.material.templates_symmetries_level_2_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/306e4272-8421-4644-b675-d1b6ea9a7d1b',
  },
  '52': {
    key: 'digitalguides.ey.material.templates_movement_cards_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/4355184e-91d0-42c0-9399-41d6b531412e',
  },
  '53': {
    key: 'digitalguides.ey.material.templates_temporal_sequence_cards_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/0d7beca8-ced6-4428-b235-93209b674bf0',
  },
  '54': {
    key: 'digitalguides.ey.material.templates_front_view_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/64617002-166f-4795-8b51-6056575d5bbe',
  },
  '55': {
    key: 'digitalguides.ey.material.templates_views_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/c501cedc-8722-43e9-9d48-22ad4d43e418',
  },
  '56': {
    key: 'digitalguides.ey.material.templates_paper_strips_of_qualities_level_1_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/ca746a5e-295f-40aa-8b02-bfdf6bd62ac9',
  },
  '57': {
    key: 'digitalguides.ey.material.templates_paper_strips_of_qualities_level_2_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/c9779318-f9e6-45b0-bd47-7ba3736e1311',
  },
  '58': {
    key: 'digitalguides.ey.material.templates_paper_strips_of_qualities_level_3_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/73c8a010-c044-4d16-83b5-b923db93465c',
  },
  '59': {
    key: 'digitalguides.ey.material.templates_compositions_with_logical_blocks_level_1_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/1c7477b5-064a-4c9f-adb1-5e6352c0b908',
  },
  '60': {
    key: 'digitalguides.ey.material.templates_compositions_with_logical_blocks_level_2_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/55e33621-c35a-4d91-9d59-3a24f0dba0a1',
  },
  '61': {
    key: 'digitalguides.ey.material.stickers-cricks',
    image:
      'https://3dt0kb75phz7.b-cdn.net/63c1f71d-7f25-4dc1-9c98-f0fb0cf4e9ad',
  },
  '62': {
    key: 'digitalguides.ey.material.templates_land_art_lines_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/5e9be117-5f7b-4c1c-bcc4-6f1faa56d84c',
  },
  '63': {
    key: 'digitalguides.ey.material.templates_land_art_geometric_compositions_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/318162d8-cc57-4d13-864b-cdaf4f0664a0',
  },
  '64': {
    key: 'digitalguides.ey.material.templates_images_of_monuments_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/6cb3c845-21fe-4586-8828-db5249deda36',
  },
  '65': {
    key: 'digitalguides.ey.material.templates_monsters_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/ddffbb6e-4742-4b1b-837e-09458210d3ff',
  },
  '66': {
    key: 'digitalguides.ey.material.templates-empty_grids_3x3_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/d9f1bb77-bdaf-4b7e-a750-0cba1384c791',
  },
  '67': {
    key: 'digitalguides.ey.material.templates-empty_grids_4x4_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/25507509-386e-4e79-8405-9a7125114cb7',
  },
  '68': {
    key: 'digitalguides.ey.material.templates-overlay_level_1_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/40172a92-c4ad-4b4a-8501-0a7adf1a6518',
  },
  '69': {
    key: 'digitalguides.ey.material.templates-overlay_level_2_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/5d295df0-a17d-4e69-a181-7a51321472ff',
  },
  '70': {
    key: 'digitalguides.ey.material.templates-translation_level_1_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/333a7db7-81f2-4190-bc6b-e54610e90156',
  },
  '71': {
    key: 'digitalguides.ey.material.templates-translation_level_2_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/d83026fc-3fbf-4689-8459-43b8d5656fdf',
  },
  '72': {
    key: 'digitalguides.ey.material.templates-quadrilaterals_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/46256ae4-dc1d-4770-b5d5-2a942cefd4e6',
  },
  '73': {
    key: 'digitalguides.ey.material.templates-mosaics_level_1_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/a7810cde-0e60-4b58-82ad-296a4c504b02',
  },
  '74': {
    key: 'digitalguides.ey.material.templates-mosaics_level_2_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/9d56378e-7c87-411d-a9e8-bf4995e95106',
  },
  '75': {
    key: 'digitalguides.ey.material.templates-hexagons_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/891083fb-34d2-4201-8571-ebf37cb637d0',
  },
  '76': {
    key: 'digitalguides.ey.material.templates-bar_graphs_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/de86b918-d94b-4b55-896f-cb6f3fc8e7e5',
  },
  '77': {
    key: 'digitalguides.ey.material.grid_geos_hive_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/75524ee9-4a6b-4615-813a-77612d6aa051',
  },
  '78': {
    key: 'digitalguides.ey.material.grid_dices_house_1_i3',
    image:
      'https://3dt0kb75phz7.b-cdn.net/f39a13e0-2a2d-4654-bf16-2bf1f93e91b6',
  },
  '79': {
    key: 'digitalguides.ey.material.grid_dices_house_2_i3_i4',
    image:
      'https://3dt0kb75phz7.b-cdn.net/c566de2f-2621-4fd1-adb1-68ce1e969b2b',
  },
  '80': {
    key: 'digitalguides.ey.material.grid_3x4_grid_i5',
    image:
      'https://3dt0kb75phz7.b-cdn.net/aef04a53-c8ca-4513-ae62-bfe59fed5305',
  },
  '81': {
    key: 'digitalguides.ey.material.templates_direction_cards',
    image:
      'https://3dt0kb75phz7.b-cdn.net/598298db-9c6b-4bcf-8d4b-08dc800bb506',
  },
  '82': {
    key: 'digitalguides.ey.material.templates_relative_spatial_position_(2)_i5',
    image:
      'https://2s08k9vad6in.b-cdn.net//4z58gz737hh-ey5-2-posicio-relativa-a-lespai-topologic-dn4-2022-2.png',
  },
  '83': {
    key: 'digitalguides.ey.material.templates_temporal_sequence_cards_level2_i3',
    image:
      'https://2s08k9vad6in.b-cdn.net//29x3senxp2tk-ey3-4-sequencies-tool-tips-n2.png',
  },
  '84': {
    key: 'digitalguides.ey.material.calendar_i3_i4_i5',
    image: 'https://2s08k9vad6in.b-cdn.net//2mimp2kgwcgm-calendar-infantil.jpg',
  },
};

const PRIMARY_MATERIALS: MaterialList = {
  '1': {
    key: 'digitalguides.pe.material.billetes_de_10d',
    image: 'https://2s08k9vad6in.b-cdn.net//18kba71kg539-dsc07138.jpg',
  },
  '2': {
    key: 'digitalguides.pe.material.billetes_de_10e',
    image: 'https://2s08k9vad6in.b-cdn.net//56z72d91fzwk-dsc00057.jpg',
  },
  '3': {
    key: 'digitalguides.pe.material.billetes_de_100d',
    image: 'https://2s08k9vad6in.b-cdn.net//4cs2p29cein9-dsc07126.jpg',
  },
  '4': {
    key: 'digitalguides.pe.material.billetes_de_100e',
    image: 'https://2s08k9vad6in.b-cdn.net//29mxmd0t79hg-dsc00064.jpg',
  },
  '5': { key: 'digitalguides.pe.material.billetes_de_1000d', image: '' },
  '6': {
    key: 'digitalguides.pe.material.billetes_de_5d',
    image: 'https://2s08k9vad6in.b-cdn.net//72e84gm8xnnz-dsc07140.jpg',
  },
  '7': {
    key: 'digitalguides.pe.material.billetes_de_5e',
    image: 'https://2s08k9vad6in.b-cdn.net//ntp7jj4vkdj-dsc00055.jpg',
  },
  '8': {
    key: 'digitalguides.pe.material.billetes_de_50e',
    image: 'https://2s08k9vad6in.b-cdn.net//rzxd4kkt69x-dsc00061.jpg',
  },
  '9': {
    key: 'digitalguides.pe.material.billetes_mixt_e',
    image: 'https://2s08k9vad6in.b-cdn.net//4k8vql4eezhf-dsc00076.jpg',
  },
  '10': {
    key: 'digitalguides.pe.material.bloques_de_goma_eva',
    image: 'https://2s08k9vad6in.b-cdn.net//2l2ng3frpqhi-dsc00024.jpg',
  },
  '11': {
    key: 'digitalguides.pe.material.bolas_verdes_y_amarillas_y_cordones',
    image: 'https://2s08k9vad6in.b-cdn.net//2lr8wwsn9ln2-dsc00070.jpg',
  },
  '12': {
    key: 'digitalguides.pe.material.cartas_de_signos',
    image: 'https://2s08k9vad6in.b-cdn.net//7f9rl7332g-dsc00063.jpg',
  },
  '13': {
    key: 'digitalguides.pe.material.cartas_numéricas_0100',
    image: 'https://2s08k9vad6in.b-cdn.net//1x0sqiq7ybky-dsc00035.jpg',
  },
  '14': {
    key: 'digitalguides.pe.material.cartas_numéricas_120',
    image: 'https://2s08k9vad6in.b-cdn.net//phon5p23k11-dsc00060.jpg',
  },
  '15': {
    key: 'digitalguides.pe.material.centenas_bloques_base_10',
    image: 'https://2s08k9vad6in.b-cdn.net//2qxbp257d6p0-dsc00036.jpg',
  },
  '16': {
    key: 'digitalguides.pe.material.cubitos_encajables',
    image: 'https://2s08k9vad6in.b-cdn.net//1wi2j7zo4yzo-dsc00046.jpg',
  },
  '17': {
    key: 'digitalguides.pe.material.cuerpos_3d',
    image: 'https://2s08k9vad6in.b-cdn.net//1w7g268f3bxg-dsc00001-2.jpg',
  },
  '18': {
    key: 'digitalguides.pe.material.dados',
    image: 'https://2s08k9vad6in.b-cdn.net//1iktvterpyc-dsc00121.jpg',
  },
  '19': {
    key: 'digitalguides.pe.material.decenas_bloques_base_10',
    image: 'https://2s08k9vad6in.b-cdn.net//7h7ggw5ze9er-dsc00039.jpg',
  },
  '20': {
    key: 'digitalguides.pe.material.dípticos_de_espejos_1010',
    image:
      'https://2s08k9vad6in.b-cdn.net//s3-innovamat-innovamat-education/6b70c002-f4ab-4c8e-a133-080c92473edf.jpg',
  },
  '21': {
    key: 'digitalguides.pe.material.fichas_planas',
    image:
      'https://2s08k9vad6in.b-cdn.net//s3-innovamat-innovamat-education/b98a03d1-5039-44fe-a06d-1e2438107fa5.jpg',
  },
  '22': {
    key: 'digitalguides.pe.material.geoplanos',
    image: 'https://2s08k9vad6in.b-cdn.net//82vestkrrxg-dsc00074.jpg',
  },
  '23': {
    key: 'digitalguides.pe.material.millares_bloques_base_10',
    image: 'https://2s08k9vad6in.b-cdn.net//mhq89mo6u5g-dsc00031.jpg',
  },
  '24': {
    key: 'digitalguides.pe.material.monedas_de_1d_y_céntimos',
    image: 'https://2s08k9vad6in.b-cdn.net//2gpyrc5q2ogq-dsc07121.jpg',
  },
  '25': {
    key: 'digitalguides.pe.material.monedas_de_1e_2e_y_céntimos',
    image: 'https://2s08k9vad6in.b-cdn.net//1i9olotdhd7m-dsc00039-1.jpg',
  },
  '26': {
    key: 'digitalguides.pe.material.monedas_de_peso',
    image: 'https://2s08k9vad6in.b-cdn.net//1pjlvbigw1a4-dsc07124.jpg',
  },
  '27': {
    key: 'digitalguides.pe.material.números_del_1_al_100_para_póster',
    image: 'https://2s08k9vad6in.b-cdn.net//654xxtdnqskh-dsc00052.jpg',
  },
  '28': {
    key: 'digitalguides.pe.material.pattern_blocks',
    image: 'https://2s08k9vad6in.b-cdn.net//4eaw16r3ujoh-dsc00093.jpg',
  },
  '29': {
    key: 'digitalguides.pe.material.piezas_de_fracciones_circulares_y_rectangulares',
    image: 'https://2s08k9vad6in.b-cdn.net//7kmmjffml4wx-dsc00028.jpg',
  },
  '30': {
    key: 'digitalguides.pe.material.póster_tablero_del_100',
    image: 'https://2s08k9vad6in.b-cdn.net//5yx9sf1lhf5f-dsc00087.jpg',
  },
  '31': {
    key: 'digitalguides.pe.material.segmentos_de_longitudes_diversas',
    image: 'https://2s08k9vad6in.b-cdn.net//5ye6gyqoza5d-dsc00007.jpg',
  },
  '32': {
    key: 'digitalguides.pe.material.tablero_del_100',
    image: 'https://2s08k9vad6in.b-cdn.net//57wi8lzuz1uq-dsc00082.jpg',
  },
  '33': {
    key: 'digitalguides.pe.material.tangram_de_cartón',
    image: 'https://2s08k9vad6in.b-cdn.net//qdin9e4ig4k-dsc00129-2.jpg',
  },
  '34': {
    key: 'digitalguides.pe.material.tarjetas_trenecito',
    image: 'https://2s08k9vad6in.b-cdn.net//6thxk0bdy9lt-dsc00043.jpg',
  },
  '35': {
    key: 'digitalguides.pe.material.unidades_bloques_base_10',
    image: 'https://2s08k9vad6in.b-cdn.net//1utldk55vn90-dsc00044.jpg',
  },
  '36': {
    key: 'digitalguides.pe.material.cartas_numéricas_09',
    image: 'https://2s08k9vad6in.b-cdn.net//6fq0niqbps81-dsc00063-2.jpg',
  },
  '37': {
    key: 'digitalguides.pe.material.billetes_de_50d',
    image: 'https://2s08k9vad6in.b-cdn.net//2dlno43g9mc8-dsc07132.jpg',
  },
  '38': {
    key: 'digitalguides.pe.material.magic_pad',
    image: 'https://2s08k9vad6in.b-cdn.net//3vwlaf504wk5-dsc00002.jpg',
  },
  '39': {
    key: 'digitalguides.pe.material.10bead_necklace',
    image: 'https://2s08k9vad6in.b-cdn.net//4qc28zybca9x-dsc00042.jpg',
  },
  '40': {
    key: 'digitalguides.pe.material.20bead_necklace',
    image: 'https://2s08k9vad6in.b-cdn.net//5lmk5a6mrkci-dsc00002-1.jpg',
  },
  '41': {
    key: 'digitalguides.pe.material.50bead_necklace',
    image: 'https://2s08k9vad6in.b-cdn.net//2woyew7qvqhx-dsc00005-1.jpg',
  },
  '42': {
    key: 'digitalguides.pe.material.100bead_necklace',
    image: 'https://2s08k9vad6in.b-cdn.net//7cmma2xwxeyv-dsc00009.jpg',
  },
  '43': {
    key: 'digitalguides.pe.material.1_notes',
    image: 'https://2s08k9vad6in.b-cdn.net//5nrgk6nthyb1-1-dollar.png',
  },
  '44': {
    key: 'digitalguides.pe.material.r_10_notes',
    image:
      'https://2s08k9vad6in.b-cdn.net//4ax4ngsqquq8-mock-ups-gestor-br-01.jpg',
  },
  '45': {
    key: 'digitalguides.pe.material.r_1_coins',
    image:
      'https://2s08k9vad6in.b-cdn.net//46mtqus7z4ej-mock-ups-gestor-br-02.jpg',
  },
  '46': {
    key: 'digitalguides.pe.material.r_5_bills',
    image:
      'https://2s08k9vad6in.b-cdn.net//4ax4ngsqquq8-mock-ups-gestor-br-01.jpg',
  },
  '47': {
    key: 'digitalguides.pe.material.r_50_bills',
    image:
      'https://2s08k9vad6in.b-cdn.net//4ax4ngsqquq8-mock-ups-gestor-br-01.jpg',
  },
  '48': {
    key: 'digitalguides.pe.material.r_100_bills',
    image:
      'https://2s08k9vad6in.b-cdn.net//4ax4ngsqquq8-mock-ups-gestor-br-01.jpg',
  },
  '49': {
    key: 'digitalguides.pe.material.r_mixed',
    image:
      'https://2s08k9vad6in.b-cdn.net//4ax4ngsqquq8-mock-ups-gestor-br-01.jpg',
  },
  '50': {
    key: 'digitalguides.pe.material.50cent_coins',
    image:
      'https://2s08k9vad6in.b-cdn.net//46mtqus7z4ej-mock-ups-gestor-br-02.jpg',
  },
  '51': {
    key: 'digitalguides.pe.material.r_20_bills',
    image:
      'https://2s08k9vad6in.b-cdn.net//4ax4ngsqquq8-mock-ups-gestor-br-01.jpg',
  },
};

export const getMaterialsByStage = (stage: Stage) => {
  const stageMaterials: Record<Stage, MaterialList> = {
    1: EARLY_YEAR_MATERIALS,
    2: PRIMARY_MATERIALS,
    3: SECONDARY_MATERIALS,
  };
  return stageMaterials[stage];
};

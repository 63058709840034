import { dates } from '@innovamat/radiance-utils';
import { useMemo, useRef, useState } from 'react';
import { SCHEDULE_DEFAULT_VALUES } from '../constants';

type ScheduleValues = {
  scheduleStart: string;
  scheduleEnd: string;
  timeZone: string | null;
};

type UseScheduleValues = {
  values: ScheduleValues;
  hoursOptions: { label: string; value: string }[];
  timeZonesOptions: { label: string; value: string }[];
  areAllFieldsFilled: boolean;
  handleUpdateSchedule: (schedule: keyof ScheduleValues, value: string) => void;
  handleResetForm: () => void;
  handleInitValues: (init: ScheduleValues) => void;
  handleTimeZoneConfirmation: () => void;
  showTimeZoneConfirmation: boolean;
  handleTimeZoneCancel: () => void;
};

const timeZonesOptions = dates.getAllTimeZonesWithGMT().map((timeZone) => ({
  label: `${timeZone.timeZone.replace('/', ', ')} (${timeZone.gmtOffset})`,
  value: timeZone.timeZone ?? '',
}));

function formatHourAndMinutes(date: string): string {
  const [hour, minutes] = date.split(':');
  return `${hour}:${minutes}`;
}

const hoursOptions = dates.getDayHours().map((hour) => ({
  label: formatHourAndMinutes(hour),
  value: hour,
}));

function useClassroomScheduleValues(): UseScheduleValues {
  const [scheduleValues, setScheduleValues] = useState<ScheduleValues>(
    SCHEDULE_DEFAULT_VALUES
  );

  const [showTimeZoneConfirmation, setShowTimeZoneConfirmation] =
    useState(false);

  const temporalTimezoneValue = useRef<string>('');

  const handleResetForm = (): void => {
    setScheduleValues(SCHEDULE_DEFAULT_VALUES);
  };

  const handleInitValues = (init: ScheduleValues): void => {
    setScheduleValues(init);
  };

  const handleUpdateSchedule = (
    schedule: keyof ScheduleValues,
    value: string
  ): void => {
    if (schedule === 'timeZone') {
      setShowTimeZoneConfirmation(true);
      temporalTimezoneValue.current = value;
    } else {
      setScheduleValues((prev) => ({ ...prev, [schedule]: value }));
    }
  };

  const handleTimeZoneConfirmation = (): void => {
    setScheduleValues((prev) => ({
      ...prev,
      timeZone: temporalTimezoneValue.current,
    }));
    setShowTimeZoneConfirmation(false);
  };

  const handleTimeZoneCancel = (): void => {
    setShowTimeZoneConfirmation(false);
    temporalTimezoneValue.current = '';
  };

  const areAllFieldsFilled = useMemo(() => {
    return Boolean(
      scheduleValues.scheduleStart &&
        scheduleValues.scheduleEnd &&
        scheduleValues.timeZone
    );
  }, [scheduleValues]);

  return {
    areAllFieldsFilled,
    handleInitValues,
    handleResetForm,
    handleUpdateSchedule,
    handleTimeZoneConfirmation,
    showTimeZoneConfirmation,
    handleTimeZoneCancel,
    hoursOptions,
    timeZonesOptions,
    values: scheduleValues,
  };
}

export { useClassroomScheduleValues, SCHEDULE_DEFAULT_VALUES };

export type { ScheduleValues, UseScheduleValues };

import {
  TableCell,
  TableContainer,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import styled from '@emotion/styled';
import { Typography } from '../Typography';
import { Icon } from '../Icon';

export const TableCellStyled = styled(TableCell)<{
  isSticky?: boolean;
  separator?: boolean;
  left?: number;
  isHiddenTableCell?: boolean;
  backgroundColor?: string;
}>`
  position: sticky !important;
  left: ${({ isSticky, left }) => (isSticky ? `${left}px` : '')};
  z-index: ${({ isSticky }) => (isSticky ? '7 !important' : '5 !important')};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.tokens.color.global.white.value};
  max-width: 100px;
  padding: ${({ separator }) =>
    separator ? '0 !important' : '8px !important'};
  display: ${({ isHiddenTableCell }) =>
    isHiddenTableCell ? 'none !important' : ''};
`;

export const HeaderTableCellStyled = styled(TableCell)<{
  isSticky?: boolean;
  left?: number;
  separator?: boolean;
  hasSubheader?: boolean;
  backgroundColor?: string;
}>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.tokens.color.global.white.value};
  position: ${({ isSticky }) => (isSticky ? 'sticky !important' : '')};
  left: ${({ isSticky, left }) => (isSticky ? `${left}px` : '')};
  z-index: ${({ isSticky }) => (isSticky ? '15 !important' : '11 !important')};
  line-height: 16px !important;
  top: -30px !important;

  border-bottom: ${({ hasSubheader, theme }) =>
    hasSubheader
      ? `1px solid ${theme.tokens.color.specific.reports.tables['divider-secondary'].value}`
      : `1px solid ${theme.tokens.color.specific.reports.tables['divider-primary'].value}`};

  padding: ${({ separator }) =>
    separator ? '0 !important' : '8px 6px 0px !important'};
`;

export const SeparatorWrapper = styled.div`
  padding: 0 4.5px;
  margin: -4px 0px;
  position: relative;
`;

export const Separator = styled.div<{ isDarkSeparator?: boolean }>`
  width: 1px;
  height: 78px;
  margin: 0 -1px;
  background-color: ${({ theme, isDarkSeparator }) =>
    isDarkSeparator
      ? theme.tokens.color.specific.reports.tables['divider-primary'].value
      : theme.tokens.color.specific.reports.tables['divider-secondary'].value};
  position: absolute;
  top: -20px;
`;

export const TableCellFirstRow = styled(TableCell)<{
  isSticky?: boolean;
  left: number;
  separator?: boolean;
}>`
  vertical-align: ${({ separator }) => (separator ? 'inherit' : 'bottom')};
  position: ${({ isSticky }) => (isSticky ? 'sticky' : '')};
  left: ${({ isSticky, left }) => (isSticky ? `${left}px` : '')};
  z-index: ${({ isSticky }) => (isSticky ? '8 !important' : '7 !important')};
  background-color: ${({ theme }) =>
    theme.tokens.color.specific.reports.tables['background-highlight'].value +
    '!important'};
  border-bottom: 1px solid
    ${({ theme }) =>
      theme.tokens.color.specific.reports.tables['divider-primary'].value +
      '!important'};
  padding: ${({ separator }) =>
    separator ? '0 !important' : '8px !important'};
`;

export const TableFirstRow = styled(TableRow)`
  position: sticky;
  top: 57px;
  z-index: 10;
`;

export const SubLabel = styled(Typography.Body3)`
  display: flex;
  margin: 0;
`;

export const ExtendIcon = styled(Icon)<{ isOpenColumn?: boolean }>`
  transform: ${({ isOpenColumn }) =>
    isOpenColumn ? 'rotate(180deg) translateY(4px)' : 'translateY(-2px)'};
  align-self: flex-end;
`;

export const SublabelContainer = styled.div<{
  isNotExtendableColumn: boolean;
  hasExtendableContent?: boolean;
}>`
  pointer-events: ${({ isNotExtendableColumn, hasExtendableContent }) =>
    isNotExtendableColumn || !hasExtendableContent ? 'none' : 'auto'};
  ${({ isNotExtendableColumn }) => {
    if (isNotExtendableColumn) {
      return `
      cursor: default;
    `;
    }
    return `
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    `;
  }}
`;

export const TableContainerStyled = styled(TableContainer)`
  position: relative;
  scrollbar-color: hsl(0, 0%, 80%);
  scrollbar-width: thin;
  max-height: 100% !important;
  overflow-y: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    @media (orientation: portrait) {
      max-height: 100%;
    }
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.tokens.color.global.white.value};
  }

  ::-webkit-scrollbar-thumb {
    background: hsl(0, 0%, 80%);
    border-radius: 4px;
  }

  .no-border-bottom:first-child {
    border: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* min-height: 68px; */
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const TableSortLabelWrapper = styled(TableSortLabel)`
  gap: 8px !important;
  display: flex !important;
  align-items: flex-start !important;
  /* min-height: 68px; */
  padding: 4px 0px;
`;

export const Title = styled(Typography.Subtitle2)`
  line-height: 1rem !important;
  display: inline-flex;
  gap: 8px;
  span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    width: fit-content;
    cursor: default;
  }
`;

export const LabelInfoIcon = styled.div`
  transform: translateY(-8px);
`;

export const LabelWithIconArrow = styled(Typography.Subtitle2)<{
  largeLabel?: boolean;
}>`
  margin-right: -2px;
  line-height: 1rem !important;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  overflow-wrap: ${({ largeLabel }) => (largeLabel ? 'break-word' : '')};
  width: ${({ largeLabel }) => (largeLabel ? '70px' : 'auto')};
`;

export const SortIcon = styled(Icon)<{
  active?: boolean;
  direction: 'asc' | 'desc';
}>`
  ${({ active, direction, theme }) => {
    const baseTokenReport = theme.tokens.color.alias.cm.icon;
    const getColor = (isActive?: boolean, currentDirection?: string): string =>
      isActive && direction === currentDirection
        ? baseTokenReport['icon-default'].value
        : baseTokenReport['icon-disabled'].value;

    return `
      path:first-child {
        stroke: ${getColor(active, 'desc')};
        fill: ${getColor(active, 'desc')};
      }
      path:last-child {
        stroke: ${getColor(active, 'asc')};
        fill: ${getColor(active, 'asc')};
      }
    `;
  }}
`;

export const StickyHeader = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.tokens.color.global.white.value};
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

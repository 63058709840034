import { Icon } from '../Icon';
import { IconType } from '@innovamat/glimmer-icons';
import { Typography } from '../Typography';
import styled from '@emotion/styled';
import ReactSelect, { type Styles, type Theme } from 'react-select';
import { useGlimmerTheme } from '../../theme';
import { useDevice } from '../../hooks/useDevice';
import { css } from '@emotion/react';
import { InputStatus } from '../input';

type Props = {
  value?: string | null;
  onChange: (value: OptionType | null) => void;
  leftIcon?: IconType;
  placeholder?: string;
  labelText?: string | JSX.Element;
  descriptiveText?: string | JSX.Element;
  status?: InputStatus;
  options: OptionType[];
  menuPlacement?: 'bottom' | 'top' | 'auto';
  isSearchable?: boolean;
  menuPortalTarget?: HTMLElement | null;
  dataTestId?: string;
  className?: string;
  defaultMenuIsOpen?: boolean;
};

export type OptionType = {
  value: string;
  label: string;
};

type Typography = {
  fontFamily: string;
  fontWeight: number;
  fontSize: string;
  lineHeight: string;
};

const Wrapper = styled.div<{ status?: InputStatus }>`
  ${({ theme, status }) => {
    const elementDisabled =
      theme.tokens.color.alias.cm.text['text-disabled'].value;
    const backgroundAutofilled =
      theme.tokens.color.alias.cm.bg['bg-neutral-subtle'].value;

    return css`
      display: flex;
      flex-direction: column;
      gap: 8px;

      ${status === 'disabled' &&
      `
    * {
      color: ${elementDisabled} !important;
    }

    .react-select__control {
      background-color: ${theme.tokens.color.global.white.value} !important;
      outline: 1px solid ${elementDisabled};

    path {
      fill: ${elementDisabled} !important;
    }
    `}

      ${status === 'autofilled' &&
      `
    .react-select__control {
      outline: 1px solid ${elementDisabled} !important;
      outline-offset: -1px;
      background-color: ${backgroundAutofilled} !important;

      input {
        color: ${elementDisabled} !important;
      }

      path {
      fill: ${elementDisabled} !important;
    }

    }
      `}
    `;
  }}
`;

const DescriptiveText = styled(Typography.Caption)`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

export function Select({
  labelText,
  status,
  value,
  onChange,
  placeholder,
  options,
  descriptiveText,
  menuPlacement,
  isSearchable,
  menuPortalTarget,
  dataTestId,
  className,
  defaultMenuIsOpen,
}: Props): JSX.Element {
  const glimmerTheme = useGlimmerTheme();
  const isMobile = useDevice();

  const typography = (type: 'Body 2' | 'Body 1'): Typography => {
    const device = isMobile ? 'Mobile' : 'Desktop';
    return {
      fontFamily: glimmerTheme.tokens.typography[device][type].value.fontFamily,
      fontWeight: glimmerTheme.tokens.typography[device][type].value.fontWeight,
      fontSize: glimmerTheme.tokens.typography[device][type].value.fontSize,
      lineHeight: glimmerTheme.tokens.typography[device][type].value.lineHeight,
    };
  };

  const customStyles: Styles<OptionType, false> = {
    control: (provided) => ({
      ...provided,
      outline: `1px solid ${glimmerTheme.tokens.color.alias.cm.border['border-default'].value}`,
      '&:hover': {
        outline: `1px solid ${glimmerTheme.tokens.color.alias.cm.border['border-inverted'].value}`,
      },
      borderRadius: '4px',
      border: 'none',
      padding: '0 8px',
      marginBottom: '1px',
      outlineOffset: '-1px',

      '&:focus-within': {
        outline: `2px solid ${glimmerTheme.tokens.color.alias.cm.border['border-default'].value}`,
        outlineOffset: '-2px',
        clipPath: 'none',
        boxShadow: 'none',
      },

      ...typography('Body 1'),
    }),
    container: (provided) => ({
      ...provided,
      outline: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      zIndex: 100,
      '&:first-child': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      '&:last-child': {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      padding: '14px 16px',
      color: state.isSelected
        ? glimmerTheme.tokens.color.alias.cm.text.text.value
        : provided.color,
      ...typography('Body 2'),
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '0',
      padding: '0',
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  };

  const customTheme = (theme: Theme): Theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: glimmerTheme.tokens.color.global.neutral.neutral200.value,
      primary25: glimmerTheme.tokens.color.global.neutral.neutral100.value,
      primary50: glimmerTheme.tokens.color.global.neutral.neutral200.value,
    },
  });

  return (
    <Wrapper status={status} data-testid={dataTestId} className={className}>
      {labelText && typeof labelText === 'string' ? (
        <Typography.Body2 id={labelText}>{labelText}</Typography.Body2>
      ) : (
        labelText
      )}
      <ReactSelect
        defaultMenuIsOpen={defaultMenuIsOpen}
        role="listbox"
        aria-labelledby={typeof labelText === 'string' ? labelText : undefined}
        aria-label={typeof labelText === 'string' ? labelText : 'Select'}
        menuPortalTarget={menuPortalTarget}
        className="react-select-container"
        classNamePrefix="react-select"
        status={status}
        isDisabled={status === 'disabled' || status === 'autofilled'}
        onChange={onChange}
        placeholder={placeholder ? placeholder : '...'}
        options={options}
        styles={customStyles}
        value={options.find((option) => option.value === value)}
        theme={customTheme}
        components={{
          DropdownIndicator: () => <Icon icon={'ExpandMoreIcon'} size="L" />,
        }}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
      />
      {descriptiveText && <DescriptiveText>{descriptiveText}</DescriptiveText>}
    </Wrapper>
  );
}

import { StandardContent } from '@innovamat/resource-viewer';
import { ContentBlockTag, StandardTag } from '../types/SmartGuide';

const isBlock = (value: string) => {
  const regex = /^block_\d+$/;
  return regex.test(value);
};

const getStandardBlocks = (
  standardContent: StandardContent[]
): ContentBlockTag[] => {
  return standardContent
    ?.filter((standard) => isBlock(standard.blockKey))
    .map((standard) => ({
      textKey: standard.blockKey,
      isEnabled: true,
    }));
};

const getStandardTags = (standardContent: StandardContent[]): StandardTag[] => {
  return standardContent
    ?.filter((standard) => !isBlock(standard.blockKey))
    .map((standard) => ({
      text: standard.blockKey,
      tooltipKey: standard.blockValue,
    }));
};

export { getStandardBlocks, getStandardTags };

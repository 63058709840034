import {
  ClassroomBody,
  useUpdateTimezoneMutation,
  usePutClassroomMutation,
  GlowApiError,
  useUpdateDigitalPracticeAccessMutation,
} from '@innovamat/glow-api-client';
import { updateOrganizationTimezone } from '../cache-actions';
import { useQueryClient } from '@tanstack/react-query';

export const DIGITAL_PRACTICE_ACCESS = {
  NO_ACCESS: 'NO_ACCESS',
  HOME_TRAINING: 'HOME_TRAINING',
  HOME_ACCESS: 'HOME_ACCESS',
} as const;

export type DigitalPracticeAccess =
  (typeof DIGITAL_PRACTICE_ACCESS)[keyof typeof DIGITAL_PRACTICE_ACCESS];

type UsePutClassroom = {
  handlePutClassroom: (
    body: ClassroomBody,
    timezone?: string,
    digitalPracticeAccess?: DigitalPracticeAccess
  ) => void;
  isPending: boolean;
};

type Props = {
  onSuccess?: (classroomBody: ClassroomBody) => void;
  onError?: (errors: GlowApiError) => void;
};

function usePutClassrooom({ onSuccess, onError }: Props): UsePutClassroom {
  const { mutate, isPending } = usePutClassroomMutation<GlowApiError>();
  const { mutate: updateTimezoneMutation, isPending: isPendingTimezone } =
    useUpdateTimezoneMutation<GlowApiError>();
  const {
    mutate: updateDigitalPracticeAccess,
    isPending: isPendingDigitalPracticeAccess,
  } = useUpdateDigitalPracticeAccessMutation<GlowApiError>();

  const queryClient = useQueryClient();

  const handlePutClassroom = (
    body: ClassroomBody,
    timezone?: string | null,
    digitalPracticeAccess?: DigitalPracticeAccess
  ): void => {
    let successCount = 0;

    const checkSuccess = () => {
      successCount += 1;
      if (
        successCount === 2 ||
        (successCount === 1 && (!timezone || !digitalPracticeAccess))
      ) {
        onSuccess?.(body);
      }
    };

    mutate(
      {
        classroom: body,
      },
      {
        onSuccess() {
          if (!timezone && !digitalPracticeAccess) {
            onSuccess?.(body);
            return;
          }

          if (digitalPracticeAccess) {
            updateDigitalPracticeAccess(
              {
                body: {
                  digitalPracticeAccess,
                  classroomId: body.id!,
                },
              },
              {
                onSuccess: checkSuccess,
                onError(errors) {
                  onError?.(errors);
                },
              }
            );
          } else {
            successCount += 1; 
          }

          if (timezone) {
            updateTimezoneMutation(
              {
                body: {
                  timeZone: timezone,
                  organizationId: body.organizationId!,
                },
              },
              {
                onSuccess() {
                  checkSuccess();
                  updateOrganizationTimezone({
                    queryClient,
                    orgId: body.organizationId!,
                    timezone,
                  });
                },
                onError(errors) {
                  onError?.(errors);
                },
              }
            );
          } else {
            successCount += 1;
          }
        },
        onError(errors) {
          onError?.(errors);
        },
      }
    );
  };

  return {
    handlePutClassroom,
    isPending: isPending || isPendingTimezone || isPendingDigitalPracticeAccess,
  };
}

export { usePutClassrooom };
